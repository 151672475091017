import { ReactNode, useEffect, useMemo, useState } from 'react'
import { Trigger, Message, Button, Modal } from '@nbit/arco'
import { t } from '@lingui/macro'
import { link } from '@/helper/link'
import { useCopyToClipboard } from 'react-use'
import { getMemberUserLoginOut } from '@/apis/user'
import { getUserInfo, removeC2CParamsTipsCache } from '@/helper/cache'
import { removeToken } from '@/helper/auth'
import { UserKycTypeEnum } from '@/constants/user'
import { useUserStore } from '@/store/user'
import { usePageContext } from '@/hooks/use-page-context'
import { getMergeModeStatus } from '@/features/user/utils/common'
import Icon from '@/components/icon'
import { useInitVipCodeDict, useVipUpgradeConditionsList, useVipUserInfo } from '@/hooks/features/vip/vip-perks'
import { isEmpty } from 'lodash'
import { ModuleEnum } from '@/constants/module-config'
import { getModuleStatusByKey } from '@/helper/module-config'
import { getDiscountAmt, getVipHeaderValue } from '@/helper/vip'
import { useSignInActivityStore } from '@/store/marketing/sign-in-activity'
import {
  getVipCenterRoutePath,
  getVipSettingRoutePath,
  getPersonalAccountSecurityPath,
  getAssetsAllRoutePath,
  getRecreationMyBetPageRoutePath,
  getPersonalCenterSettingsSecurityPath,
  getWelfareCenterPath,
  getAgentCenterPath,
  getAgentPath,
} from '@/helper/route'
import { getSpecialOffersPath } from '@/helper/route/special-offers'
import { useAppContentType } from '@/hooks/use-app-content-type'
import { getIsDemoUser } from '@/helper/user'
import UserAvatar from '../../common/user-avatar'
import styles from './index.module.css'

interface MenuCellListType {
  key: number
  /** 图标 */
  icon: ReactNode
  /** 文字 */
  text: string
  /** 是否跳转 */
  isLink: boolean
  /** 路由地址 */
  link?: string
  /** 弹窗类型 */
  type?: string
}

enum aboutEnum {
  about = 'about',
}

enum vipCenterEnum {
  vipCenter = 'vipCenter',
}

function MenuCell({ onLink, isMergeMode }) {
  const store = useUserStore()
  const userInfo = store.userInfo || getUserInfo()
  const { userConfig } = useVipUserInfo()
  // const rates = {
  //   key: 1,
  //   icon: <Icon name="icon_personal_public_percentage" fontSize={24} hasTheme />,
  //   text: t`features_user_personal_center_menu_navigation_index_rt7qwvslbp`,
  //   isLink: true,
  //   link: getVipTierRoutePath(),
  //   rightText: null,
  // isShow: true,
  // }

  const vipCenter = {
    key: 2,
    icon: <Icon name="icon_personal_public_vip_v2" fontSize={24} />,
    text: t`features_user_personal_center_menu_navigation_index_kgmvkfruho`,
    isLink: userInfo?.kycType !== UserKycTypeEnum.notCertified || !userInfo?.vipKycLimit,
    link: getVipCenterRoutePath(),
    rightText: (
      <span className="text-text_color_02 text-xs">
        {t`features_user_personal_center_menu_navigation_index_fqrtyf74pe`} {userConfig?.levelCode || ''}
      </span>
    ),
    type: vipCenterEnum.vipCenter,
    isShow: true,
  }

  // const kycAuthentication = {
  //   key: 3,
  //   icon: <Icon name="icon_personal_public_identity" fontSize={24} hasTheme />,
  //   text: t`constants_agent_invite_index_vzl3j_iht7`,
  //   isLink: true,
  //   link: getKycManagementRoutePath(),
  //   rightText: (
  //     <div
  //       className={cn(
  //         `tag ${userInfo?.kycType === UserKycTypeEnum.notCertified ? 'off' : 'on'}`,
  //         'cursor-pointer',
  //         'kyc-tag'
  //       )}
  //     >
  //       <LazyImage
  //         className="nb-icon-png"
  //         src={`${oss_svg_image_domain_address}${
  //           userInfo?.kycType === UserKycTypeEnum.notCertified
  //             ? 'user_kyc_not_verified.png'
  //             : 'user_safety_label_activated.svg'
  //         }`}
  //         width={8}
  //         height={8}
  //       />
  //       <label className="cursor-pointer">
  //         {userInfo?.kycType === UserKycTypeEnum.notCertified && t`user.personal_center_03`}
  //         {userInfo?.kycType === UserKycTypeEnum.standardCertification &&
  //           t`features_user_personal_center_menu_navigation_index_5101265`}
  //         {userInfo?.kycType === UserKycTypeEnum.advancedCertification &&
  //           t`features_user_personal_center_menu_navigation_index_5101266`}
  //         {userInfo?.kycType === UserKycTypeEnum.enterpriseCertification &&
  //           t`features/user/personal-center/profile/index-17`}
  //       </label>
  //     </div>
  //   ),
  // }

  const accountSecurity = {
    key: 4,
    icon: <Icon name="user_down_security_v2" fontSize={24} />,
    text: t`user.personal_center_09`,
    isLink: true,
    link: getPersonalAccountSecurityPath(),
    rightText: null,
    isShow: !getIsDemoUser(),
  }

  const invitationRebate = {
    key: 5,
    icon: <Icon name="icon_personal_public_gift_v2" fontSize={24} />,
    text: t`user.personal_center_05`,
    isLink: true,
    link: getAgentPath(),
    rightText: null,
    isShow: !getIsDemoUser(),
  }

  const allianceCenter = {
    key: 6,
    icon: <Icon name="icon_alliance_center_v2" fontSize={24} />,
    text: t`modules_agent_agency_center_index_page_jyovg1cnix`,
    isLink: true,
    link: getAgentCenterPath(),
    rightText: null,
    isShow: !getIsDemoUser(),
  }

  const welfareCenter = {
    key: 7,
    icon: <Icon name="icon_personal_public_ticket_v2" fontSize={24} />,
    text: t`features_welfare_center_index_vg4pz_d6jn`,
    isLink: true,
    link: getWelfareCenterPath(),
    rightText: null,
    // isShow: !getIsDemoUser(),
    isShow: false,
  }

  // const aPIManagement = {
  //   key: 8,
  //   icon: <Icon name="a-icon_personal_public_api_whitei" fontSize={24} />,
  //   text: t`features_user_personal_center_settings_api_index_5101354`,
  //   isLink: true,
  //   link: getPersonalCenterSettingsApiSecurityPath(),
  //   rightText: null,
  // }

  const settings = {
    key: 9,
    icon: <Icon name="user_anatar_site_icon_v2" fontSize={24} />,
    text: t`features_trade_trade_setting_index_2510`,
    isLink: true,
    link: getPersonalCenterSettingsSecurityPath(),
    rightText: null,
    isShow: true,
  }

  const assetsPage = {
    key: 10,
    icon: <Icon name="icon_personalcenter_assets" fontSize={24} />,
    text: t`assets.index.overview.menuName`,
    isLink: true,
    link: getAssetsAllRoutePath(),
    rightText: null,
    isShow: true,
  }

  const bettingRecord = {
    key: 11,
    icon: <Icon name="icon_personalcenter_bettingrecord" fontSize={24} />,
    text: t`features_user_personal_center_menu_navigation_index_rq71h55iox`,
    isLink: true,
    link: getRecreationMyBetPageRoutePath(),
    rightText: null,
    isShow: true,
  }

  const specialOffers = {
    key: 12,
    icon: <Icon name="asset_4" fontSize={24} />,
    text: t`features_user_personal_center_menu_navigation_index_b1co2kgosj`,
    isLink: true,
    link: getSpecialOffersPath(),
    rightText: null,
    isShow: !getIsDemoUser(),
  }
  let menuList = isMergeMode
    ? []
    : getModuleStatusByKey(ModuleEnum.vip)
    ? [
        assetsPage,
        bettingRecord,
        accountSecurity,
        vipCenter,
        invitationRebate,
        allianceCenter,
        specialOffers,
        welfareCenter,
        settings,
      ]
    : [
        assetsPage,
        bettingRecord,
        accountSecurity,
        invitationRebate,
        allianceCenter,
        specialOffers,
        welfareCenter,
        settings,
      ]
  menuList = menuList.filter(v => v.isShow)

  return (
    <>
      {menuList.map(v => {
        if ((v as any).type === vipCenterEnum.vipCenter && !userInfo?.showVipMenu) return null
        return (
          <div className="cell" key={v.key} onClick={() => onLink(v)}>
            <div className="cell-wrap">
              <div className="icon">{v.icon}</div>
              <div className="text">
                <label className="text-sm">{v.text}</label>
              </div>
              {v?.rightText && <span className="suffix-text">{v.rightText}</span>}
            </div>
          </div>
        )
      })}
    </>
  )
}

function MenuVipDiscountBar() {
  useInitVipCodeDict()
  const { userConfig } = useVipUserInfo()
  const { levelCode } = userConfig || {}
  const list = useVipUpgradeConditionsList()
  const data = list?.find(e => e.levelCode?.toString() === levelCode?.toString())
  if (isEmpty(data?.feeList)) return <div></div>
  return (
    <div className="bg-card_bg_color_01 rounded-lg text-xs p-3 space-y-2">
      {data!.feeList!.map((fee, idx) => (
        <div className="flex flex-row space-x-2 justify-between" key={idx}>
          <span>
            {t({
              id: 'features_user_personal_center_menu_navigation_index_nechi3rdvy',
              values: { 0: getVipHeaderValue(fee.productCd) },
            })}
          </span>
          <div>
            <span className="text-text_color_02">
              {t`features_user_personal_center_menu_navigation_index_ak4wf2zasf`}
            </span>
            <span>
              {getDiscountAmt(fee.makerFee)}/{getDiscountAmt(fee.takerFee)}
            </span>
          </div>
        </div>
      ))}
    </div>
  )
}

function MenuHeader({ onClick, isMergeMode }) {
  const store = useUserStore()
  const userInfo = store.userInfo || getUserInfo()
  const [state, copyToClipboard] = useCopyToClipboard()
  const { hasExchange } = useAppContentType()
  const { setActivity } = useSignInActivityStore()

  const handleCopy = (key: string) => {
    copyToClipboard(key)
    state.error ? Message.error(t`user.secret_key_02`) : Message.success(t`user.secret_key_01`)
  }

  const handleSwitch = async () => {
    const res = await getMemberUserLoginOut({})
    res.isOk && Message.success(t`features_user_personal_center_menu_navigation_index_2443`)
    store.removeUserInfo()
    setActivity(null)
    store.removeC2cModeUserInfo()
    await removeToken()
    removeC2CParamsTipsCache()
    link('/login')
  }

  return (
    <div className="header">
      <div className="flex flex-row space-x-2">
        {/* avatar */}
        <UserAvatar width={40} height={40} hasFrame isFetch isUser />

        <div>
          <div
            className="name"
            onClick={() => {
              !getIsDemoUser() && onClick()
            }}
          >
            <label className={`text-base font-medium ${getIsDemoUser() ? 'name-height' : ''}`}>
              {getIsDemoUser()
                ? t`features_user_personal_center_menu_navigation_index_pwxxfiojgl`
                : userInfo?.nickName || t`user.personal_center_01`}
            </label>
            {!getIsDemoUser() && <Icon name="next_arrow" className="theme-default-icon" fontSize={10} />}
          </div>
          {!getIsDemoUser() && (
            <div className="uid">
              <label>UID: {userInfo?.uid}</label>
              <Icon
                name="copy"
                fontSize={12}
                className="text-icon_color_02"
                onClick={() => handleCopy(userInfo?.uid)}
              />
            </div>
          )}
        </div>
      </div>
      <Button long className={'switch-btn'} style={{ height: 30 }} onClick={() => handleSwitch()}>
        {t`features_user_personal_center_menu_navigation_index_wtuuy1axab`}
      </Button>
      {getModuleStatusByKey(ModuleEnum.vip) && hasExchange && <MenuVipDiscountBar />}
    </div>
  )
}

function MenuLoginOut() {
  const store = useUserStore()
  const { setActivity } = useSignInActivityStore()

  const handleLoginOut = async () => {
    const res = await getMemberUserLoginOut({})
    res.isOk && Message.success(t`features_user_personal_center_menu_navigation_index_2443`)
    store.removeUserInfo()
    setActivity(null)
    store.removeC2cModeUserInfo()
    await removeToken()
    removeC2CParamsTipsCache()
    link('/')
  }

  return (
    <div className="login-out">
      <div className="cell" onClick={() => handleLoginOut()}>
        <div className="cell-wrap">
          <div className="text text-center">
            <label>
              {getIsDemoUser()
                ? t`features_user_personal_center_menu_navigation_index_xgpnobrr2i`
                : t`user.personal_center_13`}
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

function UserPersonalCenterMenuNavigation({ isAboutShow, handleAboutPopUpShow }) {
  const { path } = usePageContext()
  const isMergeMode = getMergeModeStatus()
  const { updateUserInfoData, userInfo } = useUserStore()

  const [showKycModal, setShowKycModal] = useState<boolean>(false)
  const [popupVisible, setPopupVisible] = useState<boolean>(false)

  useEffect(() => {
    if (isAboutShow) handleAboutPopUpShow(false)
  }, [path])

  const handleLink = (v: MenuCellListType) => {
    setPopupVisible(false)
    if (v.isLink) {
      link(v.link)
      return
    }

    v.type === aboutEnum.about && handleAboutPopUpShow(true)
    v.type === vipCenterEnum.vipCenter && setShowKycModal(true)
  }

  const menuCell = useMemo(() => {
    return <MenuCell onLink={handleLink} isMergeMode={isMergeMode} />
  }, [])

  const onPersonalCenterChange = e => {
    setPopupVisible(e)
    if (e) {
      updateUserInfoData()
    }
  }

  return (
    <>
      <Trigger
        trigger="hover"
        popupAlign={{
          bottom: [-110, 16],
        }}
        popupVisible={popupVisible}
        onVisibleChange={onPersonalCenterChange}
        popup={() => (
          <div className={`user-personal-center-menu ${styles.scoped}`}>
            <div className="user-personal-center-menu-wrap">
              <MenuHeader
                onClick={() => {
                  setPopupVisible(false)
                  link(getVipSettingRoutePath())
                }}
                isMergeMode={isMergeMode}
              />

              {menuCell}
            </div>

            {!isMergeMode && <MenuLoginOut />}
          </div>
        )}
      >
        <div className="user-menu-icon">
          <Icon name="user_head_v2" fontSize={16} hover />
        </div>
      </Trigger>
      <Modal
        className={styles['is-kyc-modal']}
        title={t`features_user_personal_center_menu_navigation_index_wv2dkzm3se`}
        onCancel={() => setShowKycModal(false)}
        footer={() => (
          <>
            <Button
              onClick={() => setShowKycModal(false)}
            >{t`features_user_personal_center_menu_navigation_index_tsar4c5bui`}</Button>
            <Button
              type="primary"
              onClick={() => {
                link('/kyc-authentication-homepage')
                setShowKycModal(false)
              }}
            >
              KYC{t`features_c2c_trade_free_trade_index_ueruhwwnrlhksqf41fkqn`}
            </Button>
          </>
        )}
        visible={showKycModal}
      >
        <span>{t`features_user_personal_center_menu_navigation_index_jclv7a83ni`}</span>
      </Modal>
    </>
  )
}

export default UserPersonalCenterMenuNavigation
